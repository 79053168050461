import React, { useState, useEffect } from "react";
import { Table, Box, SpaceBetween, Button, TextFilter, Header, Pagination, CollectionPreferences, Link, Container } from "@amzn/awsui-components-react";
import { useNavigate } from 'react-router-dom';
import { mockTestResult } from "src/mocks/mockData";
import TestResultData from "src/interfaces/TestResult";
import TestCaseResult from "../TestCaseResult";

export default () => {
  const navigate = useNavigate();

  let testResult:TestResultData;
  testResult= mockTestResult()
  useEffect(() => {
    testResult= mockTestResult()
  }, [])

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <SpaceBetween direction="vertical" size="xs">
          <Container
            header={
              <Header
                variant="h2"
              >
                Test Result Summary
              </Header>
            }
          >
            <SpaceBetween direction="horizontal" size="xxl">
            {`Test Run Name :  ${testResult.TestRun}`}
            {`Overall Status: ${testResult.TestResultStatus}`}
            {`Device Tested: ${testResult.Device}`}
            {`Test Date: ${testResult.Date}`}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
        <Container>
          <SpaceBetween direction="vertical" size="xxl">
          <TestCaseResult
          testCaseResult={testResult.TestCases}
          />
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </>
  );
}