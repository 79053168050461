import React from "react";
import Button, {ButtonProps} from "@amzn/awsui-components-react/polaris/button";

interface CustomButtonProps {
    variant: ButtonProps.Variant;
    value: string;
    style?: React.CSSProperties;
    onClick?: any
}

const CustomButton: React.FC<CustomButtonProps> = ({ variant, value,style ,onClick}) => {
    return (
        <div style={style}>
            <Button onClick={onClick} variant={variant}>{value}</Button>
        </div>
    );
};

export default CustomButton;
