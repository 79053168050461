// Define the checkbox data
export const checkboxData = [
    {
        label: "Core functional (ILE, Navigation)",
        children: ["A", "B", "C"]
    },
    {
        label: "Performance testing",
        children: ["X", "Y", "Z"]
    },
    {
        label: "Feature integration testing",
        children: ["Catalog integration", "SSI log-in", "Voice Skill Kit (VSK)", "Live TV", "Atmos/Dolby"]
    },
    {
        label: "Select All"
    },
];