import React, {useState} from "react";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import FormField from "@amzn/awsui-components-react/polaris/form-field";

interface FileUploadProps {
    label?: string;
    description: string;
    value: ReadonlyArray<File>;
    onChange: any;
}

export default (props: FileUploadProps) => {
    const {label, description, onChange, value} = props;

    return (
        <FormField
            label={label}
            description={description}
        >
            <FileUpload
                onChange={onChange}
                value={value}
                i18nStrings={{
                    uploadButtonText: e =>
                        e ? "Choose files" : "Choose file",
                    dropzoneText: e =>
                        e
                            ? "Drop files to upload"
                            : "Drop file to upload",
                    removeFileAriaLabel: e =>
                        `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error"
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={3}
                constraintText=""
            />
        </FormField>
    );
}
