import { AxiosInstance } from 'axios';
import config from 'src/utils/constants/config';

export const apiHandler = (apiCaller: AxiosInstance) => {
    const URL_PATH = config.BASE_URLS[config.ENV];

    //Make versioned APIs, 
    //So that in future we can have a similar api sla, with just a version name changed. 
    //Its a common practice and makes code more readable

    const fetchDdfToken = () => {
        return apiCaller.get(`${URL_PATH}/apps-and-games/console/app-quality-central-api/ddf-jwt-token?userType=internal`
        // ,
        // { headers:{
        //     "X-Vendor-Id":"M1A0OKDKEII819",
        // }
        // }
        );
    };
    
    const fetchUserFeatures = () => {
        return apiCaller.get(`${URL_PATH}/apps-and-games/console/app-quality-central-api/feature-access`
        // ,
        //     { headers:{
        //         "X-Vendor-Id":"M1A0OKDKEII819",
        //         // "X-Vendor-Id":"M1A0OKDKEII820",
        //         // "X-Vendor-Id":"M1A0OKDKEII821",
        //         // "X-Vendor-Id":"M1A0OKDKEII822",
        //     }
        // }
        );
    };

    //Add different API handlers here itself.
    return { fetchDdfToken, fetchUserFeatures };
};