import CustomFileUpload from "src/components/FileUpload/CustomFileUpload";
import CustomContainer from "src/components/Container/CustomContainer";
import React from "react";

interface IUploadFileComponentProps {
    uploadFile: ReadonlyArray<File>;
    setUploadFile: any;
}

export default (props: IUploadFileComponentProps) => {
    const {uploadFile, setUploadFile} = props;

    const onFileUpload = (e: any) => {
        console.log("Selected file")
        console.log(e);
        setUploadFile(e.detail.value);
        console.log("Uploaded file")
        console.log(uploadFile);
    }

    return (
        <div>
            <CustomContainer title="Upload app or choose from already uploaded apps" content="" description="" variant="h2"
                             style={{marginBottom: '20px'}}>
                <CustomFileUpload
                    label=""
                    description="Drag and drop or Choose File to upload an app"
                    value = {uploadFile}
                    onChange = {(e: any) => onFileUpload(e)}
                />
            </CustomContainer>
        </div>
    );
}