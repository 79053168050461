import React from 'react';
import "src/index.scss"
import gif from "src/Assets/images/coming-soon-gif.gif"



  export default () => {
    
    return(
            <>
            <div>
                <img src={gif} alt='Coming Soon' />
            </div>
            </>
        );
}
