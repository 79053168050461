import React, {useState, useEffect} from "react";
import { Table, Box, SpaceBetween, Button, TextFilter, Header, Pagination, CollectionPreferences, Link } from "@amzn/awsui-components-react";
import { useNavigate } from 'react-router-dom';
import { mockTestRuns } from "src/mocks/mockData";


export default () => {
  const navigate = useNavigate();
  const [testRuns, settestRuns] = useState<any[]>([]);
  const testResultUrl = "/apps-and-games/console/app-quality-central/testResult/"

  useEffect(()=>{
    settestRuns(mockTestRuns())
  },[])
  return (
    <Table
      columnDefinitions={[
        {
          id: "TestRun",
          header: "Test Name",
          cell: item => <Link href={`${testResultUrl}${item['TestRun']}`}>{item['TestRun']}</Link>,
          sortingField: "name",
          isRowHeader: true
        },
        {
          id: "TestResultStatus",
          header: "Test Result Status",
          cell: item => item['TestResultStatus'],
          sortingField: "TestResultStatus"
        },
        {
          id: "TestRunStatus",
          header: "Test Run Status",
          cell: item => item['TestRunStatus'],
          sortingField: "TestRunStatus"
        },
        {
          id: "Device",
          header: "Device",
          cell: item => item['Device'],
          sortingField: "Device"
        },
        {
          id: "Date",
          header: "Date",
          cell: item => item['Date'],
          sortingField: "Date"
        }
      ]}
      columnDisplay={[
        { id: "TestRun", visible: true },
        { id: "TestRunStatus", visible: true },
        { id: "TestResultStatus", visible: true },
        { id: "Device", visible: true },
        { id: "Date", visible: true }
      ]}
      enableKeyboardNavigation
      items={testRuns}
      loadingText="Loading resources"
      resizableColumns
      stickyHeader
      stripedRows
      trackBy="TestRun"
      empty={
        <Box
          margin={{ vertical: "xs" }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="m">
            <b>No Runs</b>
            <Button variant="primary" onClick={()=>{
              navigate('/apps-and-games/console/app-quality-central/?SelectedTab=test')
            }} >Create a Test Run</Button>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          filteringPlaceholder="Find Test Runs"
          filteringText=""
        />
      }
      header={
        <Header>
          All Test Details
        </Header>
      }
      pagination={
        <Pagination currentPageIndex={1} pagesCount={2} />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            contentDisplay: [
              { id: "TestRun", visible: true },
              { id: "TestRunStatus", visible: true },
              { id: "TestResultStatus", visible: true },
              { id: "Device", visible: true },
              { id: "Date", visible: true }
            ]
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 10, label: "10 runs" },
              { value: 20, label: "20 runs" }
            ]
          }}
          // wrapLinesPreference={{}}
          // stripedRowsPreference={{}}
          // contentDensityPreference={{}}
          contentDisplayPreference={{
            options: [
              {
                id: "TestRun",
                label: "Test Name",
                alwaysVisible: true
              },
              { id: "TestRunStatus", label: "TestRunStatus" },
              { id: "TestResultStatus", label: "TestResultStatus" },
              { id: "Device", label: "Device" },
              { id: "Date", label: "Date" }
            ]
          }}
          stickyColumnsPreference={{
            firstColumns: {
              title: "Stick first column(s)",
              description:
                "Keep the first column(s) visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "First column", value: 1 },
                { label: "First two columns", value: 2 }
              ]
            },
            lastColumns: {
              title: "Stick last column",
              description:
                "Keep the last column visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "Last column", value: 1 }
              ]
            }
          }}
        />
      }
    />
  );
}