import React from 'react';
import { Route, Routes, Navigate } from 'react-router';
import Landing from 'src/containers/Landing';
import LiveDeviceInteraction from 'src/containers/LiveDeviceInteraction';
import TestResult from 'src/containers/TestResult';

export const routerConfig = [
    { path: '/apps-and-games/console/app-quality-central/', Component: Landing},
    { path: '/apps-and-games/console/app-quality-central/testResult/:testId', Component: TestResult}

];

export const redirects = [{ from: '*', to: '/apps-and-games/console/app-quality-central/' }];

export default () => {
    return (
        <Routes>
            {routerConfig.map(({ path, Component}, index) => {
                return (
                    <Route
                        key={index}
                        path={path}
                        element={
                            <>
                            <Component />
                            </>
                        }
                    />
                );
            })}
            {redirects.map(({ from, to }) => {
                return <Route key={from} path={from} element={<Navigate to={to} replace />} />;
            })}
        </Routes>
    );
};
