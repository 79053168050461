const UI_HOSTNAMES = {
    LOCAL: 'alpha.aqc.appstore.amazon.dev',
    ALPHA: 'alpha.aqc.appstore.amazon.dev',
    BETA: 'beta.aqc.appstore.amazon.dev',
    GAMMA: 'gama.aqc.appstore.amazon.dev',
    PROD: 'aqc.appstore.amazon.dev',
};

type EnvironmentType = 'LOCAL' | 'ALPHA' | 'BETA' | 'GAMMA' | 'PROD';

let env: EnvironmentType;

export const ENVIRONMENT = {
    LOCAL: 'LOCAL',
    ALPHA: 'ALPHA',
    BETA: 'BETA',
    UAT: 'GAMMA',
    PROD: 'PROD',
};

switch (window.location.hostname) {
    case UI_HOSTNAMES.ALPHA:
        env = ENVIRONMENT.ALPHA as EnvironmentType;
        break;
    case UI_HOSTNAMES.BETA:
        env = ENVIRONMENT.BETA as EnvironmentType;
        break;
    case UI_HOSTNAMES.GAMMA:
        env = ENVIRONMENT.UAT as EnvironmentType;
        break;
    case UI_HOSTNAMES.PROD:
        env = ENVIRONMENT.PROD as EnvironmentType;
        break;
    default:
        env = ENVIRONMENT.LOCAL as EnvironmentType;
        break;
}

interface IUiHostName {
    LOCAL: string;
}

interface IEndpoints {
    AQC_BACKEND: string;
}

interface IConstants {
    ENV: EnvironmentType;
    UI_HOSTNAMES: IUiHostName;
    BASE_URLS: {
        [k: string]: string;
    };
    ENDPOINTS: IEndpoints;
}

const config: IConstants = {
    ENV: env as EnvironmentType,
    UI_HOSTNAMES,
    BASE_URLS: {
        [ENVIRONMENT.LOCAL]: 'https://dev-dsk-akammili-1a-a9c45d02.eu-west-1.amazon.com:1081',
        // [ENVIRONMENT.LOCAL]: 'https://dev.aqc-api.appstore.amazon.dev',
    },
    ENDPOINTS: {
        AQC_BACKEND: '/aqc/api',
    }
};

export default config;