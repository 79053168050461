import CustomCheckbox from "src/components/CheckBox/CustomCheckBox";
import CustomContainer from "src/components/Container/CustomContainer";
import CustomTextarea from "src/components/TextArea/CustomTextArea";
import React, {useEffect, useState} from "react";
import {checkboxData} from "src/containers/TestYourApp/TestYourAppConfig";

interface ITestCasesCompProps{
    selectedParentCheckboxes: string[];
    setSelectedParentCheckboxes: any;
    selectedChildCheckboxes: { [key: string]: string[] };
    setSelectedChildCheckboxes: any;
    selectAllChecked: boolean;
    setSelectAllChecked: any;
    placeHolderValue: string;
    setPlaceHolderValue: any;
}

export default (props: ITestCasesCompProps) => {
    // const [selectedParentCheckboxes, setSelectedParentCheckboxes] = useState<string[]>([]);
    // const [selectedChildCheckboxes, setSelectedChildCheckboxes] = useState<{ [key: string]: string[] }>({});
    // const [selectAllChecked, setSelectAllChecked] = useState(false);
    // const [placeHolderValue, setPlaceHolderValue] = useState<string>('')
    const {selectedParentCheckboxes, setSelectedParentCheckboxes,
        selectedChildCheckboxes, setSelectedChildCheckboxes,
        selectAllChecked, setSelectAllChecked,
        placeHolderValue, setPlaceHolderValue} = props

    // const [selectedParentCheckboxes, setSelectedParentCheckboxes] = useState(initialSelectedParentCheckboxes);
    // const [selectedChildCheckboxes, setSelectedChildCheckboxes] = useState(initialSelectedChildCheckboxes);
    // const [selectAllChecked, setSelectAllChecked] = useState(initialSelectAllChecked);
    // const [placeHolderValue, setPlaceHolderValue] = useState(initialPlaceHolderValue);

    const [componentRender, setComponentRender] = useState(false)

    const handleTextareaChange = (e: any) => {
        console.log("Textarea value:", e?.detail?.value);
        setPlaceHolderValue(e?.detail?.value)
    };

    useEffect(() => {
        resetChildCheckboxes();
    }, []);

    const resetChildCheckboxes = () => {
        let updatedSelectedChildCheckboxes = selectedChildCheckboxes;
        checkboxData
            .filter(data => data.label != 'Select All')
            .map((data, index) =>
                updatedSelectedChildCheckboxes[data.label] = []
            );
        setSelectedChildCheckboxes(updatedSelectedChildCheckboxes);
        console.log("children checkboxes")
        console.log(selectedChildCheckboxes)
        setComponentRender(prev => !prev);
    }

    const handleSelectAllCheckboxChange = () => {
        let newSelectAllCheckedState = !selectAllChecked;
        setSelectAllChecked(newSelectAllCheckedState);

        let updatedSelectedParentCheckboxes: string[] = [];

        if (newSelectAllCheckedState) {
            // If Select All is ticked, only that parent is active and others get disabled
            updatedSelectedParentCheckboxes.push('Select All');
        } else {
            updatedSelectedParentCheckboxes = [];
        }
        // If 'Select All' is enabled or disabled, remove all child checkboxes, so that user can start afresh (if required)
        resetChildCheckboxes();

        console.log("parent")
        console.log(updatedSelectedParentCheckboxes)

        setSelectedParentCheckboxes(updatedSelectedParentCheckboxes);
    };

    const handleParentCheckboxChange = (parentCheckboxLabel: string) => {
        let updatedSelectedParentCheckboxes = [...selectedParentCheckboxes];
        let updatedSelectedChildCheckboxes = selectedChildCheckboxes;

        const index = updatedSelectedParentCheckboxes.indexOf(parentCheckboxLabel);
        if (index !== -1) { //Un-Checked now
            // index !==-1 means that parent was selected earlier, and now state has changed to un-checked
            // So, If parent checkbox is not un-checked,
            // that means it's not selected now, so remove it and its children from the selected list
            console.log("un-checked " + parentCheckboxLabel)
            updatedSelectedParentCheckboxes.splice(index, 1);
        } else { // Checked now
            // index = -1 means that parent was earlier not selected, and now state has changed to checked
            // If parent checkbox is checked,
            // that means its now selected, so add it and its children to the selected list
            console.log("checked " + parentCheckboxLabel)
            updatedSelectedParentCheckboxes.push(parentCheckboxLabel);
        }

        // If any parent checkBox is enabled or disabled, remove all its child checkboxes, so that user can start afresh (if required)
        updatedSelectedChildCheckboxes[parentCheckboxLabel] = [];

        console.log("parent checkboxes")
        console.log(updatedSelectedParentCheckboxes)
        console.log("children checkboxes")
        console.log(selectedChildCheckboxes)

        setSelectedParentCheckboxes(updatedSelectedParentCheckboxes);
        setSelectedChildCheckboxes(updatedSelectedChildCheckboxes);
        setComponentRender(prev => !prev);
    };

    const handleChildCheckboxChange = (parentLabel: string, childLabel: string, e: any) => {
        console.log("child checkbox event");
        console.log(e);
        let updatedSelectedChildCheckboxes = selectedChildCheckboxes;
        // if(e.detail.checked){
        //     // If the child checkbox is checked, add it to the array
        //     if (!updatedSelectedChildCheckboxes[parentLabel].includes(childLabel)) {
        //         updatedSelectedChildCheckboxes[parentLabel].push(childLabel);
        //     }
        // } else{
        //     // If the child checkbox is unchecked, remove it from the array
        //     let index = updatedSelectedChildCheckboxes[parentLabel].indexOf(childLabel);
        //     if(index !== -1){
        //         updatedSelectedChildCheckboxes[parentLabel].splice(index, 1);
        //     }
        // }

        if (!updatedSelectedChildCheckboxes[parentLabel].includes(childLabel)) {
            updatedSelectedChildCheckboxes[parentLabel].push(childLabel);
        }else{
            let index = updatedSelectedChildCheckboxes[parentLabel].indexOf(childLabel);
            if(index !== -1){
                updatedSelectedChildCheckboxes[parentLabel].splice(index, 1);
            }
        }

        setSelectedChildCheckboxes(updatedSelectedChildCheckboxes);
        console.log("children checkboxes")
        console.log(selectedChildCheckboxes);
        setComponentRender(prev => !prev);
    }

    const disabledRest = (label: string) => {
        return label !== 'Select All' && selectedParentCheckboxes.includes('Select All')
    }

    const getCheckedState = (parentOption: string, childOption: string) => {
        console.log("getCheckedState", " -> ",parentOption," -> " ,childOption)
        console.log(selectedParentCheckboxes.includes(parentOption) && selectedChildCheckboxes[parentOption].includes(childOption));
        return selectedParentCheckboxes.includes(parentOption) && selectedChildCheckboxes[parentOption].includes(childOption);
    }

    return (
        <div>
            <CustomContainer title="Select some specific tests or run all inbuilt test cases (Optional)" content="" description=""
                             variant="h2" style={{marginBottom: '20px'}}>
                <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    {checkboxData.map((data, index) => (
                        <div key={index} style={{marginRight: '10px', marginBottom: '10px'}}>
                            <CustomCheckbox
                                label={data.label}
                                checkedState={selectedParentCheckboxes.includes(data.label)}
                                onChange={() => data.label === 'Select All' ? handleSelectAllCheckboxChange() : handleParentCheckboxChange(data.label)}
                                disabled={disabledRest(data.label)}
                            />
                        </div>
                    ))}
                </div>
                {selectedParentCheckboxes.map((parentOption, index) => (
                    parentOption !== 'Select All' && (
                        <CustomContainer key={index} title={`Select specific ${parentOption} you want to get tested`} content=""
                                         description="" variant="h3" style={{marginBottom: '20px'}}>
                            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                {(checkboxData.find(data => data.label === parentOption)?.children || [])
                                    .map((childOption, childIndex) => (
                                        <div key={childIndex} style={{marginRight: '10px', marginBottom: '10px'}}>
                                            <CustomCheckbox
                                                label={childOption}
                                                checkedState={getCheckedState(parentOption, childOption)}
                                                // checkedState={true}
                                                onChange={(e: any) => handleChildCheckboxChange(parentOption, childOption, e)}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </CustomContainer>
                    )
                ))}
                <CustomTextarea
                    val={placeHolderValue}
                    onChange={(e: any) => handleTextareaChange(e)}
                    placeholder="Provide any test instructions, changes made in new version from earlier version"
                />
            </CustomContainer>
        </div>
    );
}