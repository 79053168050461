import React, {useEffect, useState} from 'react';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import { useSearchParams } from 'react-router-dom';
import "src/index.scss"
import Overview from 'src/containers/Overview';
import LiveDeviceInteraction from 'src/containers/LiveDeviceInteraction';
import useAxios from 'src/utils/hooks/useAxios'
import TestSummary from 'src/containers/TestSummary';
import TestYourApp from 'src/containers/TestYourApp';
import ComingSoon from 'src/containers/ComingSoon';
import { apiHandler } from 'src/utils/services/apiService';


export default () => {
  const { axios } = useAxios();
  const [userAllowlisted, setUserAllowlisted] = useState()
  const [automatedTest, setAutomatedTest] = useState()
  const [liveInteraction, setLiveInteraction] = useState()
  
  useEffect(() => {
    fetchUserAuth();
  }, []);

  const fetchUserAuth = async () => {
    try {
      const response = (await apiHandler(axios).fetchUserFeatures())?.data;
          setUserAllowlisted(response?.allowListed)
          setAutomatedTest(response?.featuresAccess?.automatedTest)
          setLiveInteraction(response?.featuresAccess?.liveInteraction)
    } catch (error) {
      console.log(error as string);
    }
  };
  const [searchParams, setSearchParams] = useSearchParams();
  // const [userAllowlisted, setUserAllowListed] = useState();
  const onTabChangeHandler = (tabId: string) => {
    setSearchParams({ SelectedTab: tabId });
  };

  

  useEffect(() => {
    onTabChangeHandler('overview')
  }, []);
  return (
    <>
    {userAllowlisted ?(
      <Tabs
        tabs={[
          {
            label: "Overview",
            id: 'overview',
            content: (
              <Overview 
                automatedtesting= {automatedTest}
                manualTesting={liveInteraction}
            />
            ),
          },
          {
            label: "LiveDeviceInteraction",
            id: 'LiveDeviceInteraction',
            content: <LiveDeviceInteraction />
          },
          {
            label: "Test Your App",
            id: 'test',
            content: <TestYourApp/>,
          },
          {
            label: "Test Summary",
            id: 'summary',
            content: (
              <TestSummary />
            ),
          },
        ]}
        onChange={(e) => {
          return onTabChangeHandler(e.detail.activeTabId);
        }}
        activeTabId={searchParams.get('SelectedTab') || 'generalInformation'}
        // variant="container"
      />
  ):
  (<ComingSoon />)
      }
  </>
  );
}