import React, { useState, useEffect } from "react";
import { Table, Box, SpaceBetween, Button, TextFilter, Header, Pagination, CollectionPreferences, Link, Container } from "@amzn/awsui-components-react";
import { useNavigate } from 'react-router-dom';

export default (props: { testCaseResult: any }) => {
  const navigate = useNavigate();
  console.log(props.testCaseResult);

  return (
    <>
    <SpaceBetween direction="vertical" size="xxl">
    {props.testCaseResult.map((test: any) => (
      <Container
        header={
          <Header
            variant="h2">
            {test.testCase}
          </Header>
        }>
          <SpaceBetween direction="horizontal" size="xxl">
            {`Test Status :  ${test.testCaseStatus}`}
            {`Description: ${test.testCaseDescription}`}
            {`artefacts: ${test.artefactsUrl}`}
            </SpaceBetween>
      </Container>))}
      </SpaceBetween>
    </>
  );
}