import React from "react";
import TestResultData from "src/interfaces/TestResult";

function mockTestRuns() {
    const testRunsMock: any[] = [
        {
            TestRun:"TestRun1",
            TestResultStatus:"Passed",
            TestRunStatus:"Completed",
            Device:"Callie",
            Date:"02-26-2024"

        },
        {
            TestRun:"TestRun2",
            TestResultStatus:"Passed",
            TestRunStatus:"Completed",
            Device:"Callie",
            Date:"02-26-2024"
        },
        {
            TestRun:"TestRun3",
            TestResultStatus:"Passed",
            TestRunStatus:"Completed",
            Device:"Callie",
            Date:"02-26-2024"
        }
    ]
    return testRunsMock;
}

function mockTestResult() {
    const testResultMock:TestResultData = 
        {
            TestRun:"Test Run 1",
            TestResultStatus:"Passed",
            Device:"Callie",
            Date:"02-26-2024",
            TestCases:[
                {
                    testCase:"Core Functional (ILE)",
                    testCaseStatus:"Pass",
                    testCaseDescription:`This is a paragraph. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Ut luctus tempor
                    dolor ac accumsan.`,
                    artefactsUrl:"https://developer.amazon.com/"

                },
                {
                    testCase:"Feature Integration",
                    testCaseStatus:"Fail",
                    testCaseDescription:`This is a paragraph. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Ut luctus tempor
                    dolor ac accumsan.`,
                    artefactsUrl:`https://developer.amazon.com/`
                    
                },
                {
                    testCase:"Performance Testing",
                    testCaseStatus:"Fail",
                    testCaseDescription:`This is a paragraph. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Ut luctus tempor
                    dolor ac accumsan.`,
                    artefactsUrl:`https://developer.amazon.com/`
                    
                }
            ]
        }
    return testResultMock;
}

export {mockTestRuns, mockTestResult}