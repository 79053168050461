import React, {ReactNode} from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header, {HeaderProps} from "@amzn/awsui-components-react/polaris/header";
interface CustomContainerProps {
    variant: HeaderProps.Variant; //'h1' | 'h2' | 'h3' | 'awsui-h1-sticky';
    description: string;
    title: string;
    content: ReactNode;
    children?: ReactNode;
    style?: React.CSSProperties;
}

const CustomContainer: React.FC<CustomContainerProps> = ({
                                                             variant,
                                                             description,
                                                             title,
                                                             content,
                                                             children,
                                                             style
                                                         }) => {
    return (
        <div style={style}>
            <Container
                header={
                    <Header
                        variant={variant}
                        description={description}
                    >
                        {title}
                    </Header>
                }
            >
                {content}
                {children} {/* Include children here */}
            </Container>
        </div>
    );
};

export default CustomContainer;
