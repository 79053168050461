import React, {useEffect, useState} from 'react';
import CustomContainer from "src/components/Container/CustomContainer";
import CustomButton from "src/components/Button/CustomButton";
import AppDropDown from "src/components/SelectDropdown/CustomSelectDropdown";
import {IAppIdList} from "src/containers/TestYourApp/type";
import UploadFileContainer from "src/containers/TestYourApp/UploadFileComponent";
import TestCasesContainer from "src/containers/TestYourApp/TestCasesComponent";
import {SelectProps} from "@amzn/awsui-components-react/polaris/select";

const ParentComponent: React.FC = () => {
    const [appIds, setAppIds] = useState<IAppIdList>([]);
    const [currentAppId, setCurrentAppId] = useState<SelectProps.Option | null>(null);
    const [selectedParentCheckboxes, setSelectedParentCheckboxes] = useState<string[]>([]);
    const [selectedChildCheckboxes, setSelectedChildCheckboxes] = useState<{ [key: string]: string[] }>({});
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [placeHolderValue, setPlaceHolderValue] = useState<string>('')
    const [uploadFile, setUploadFile] = useState([]);

    useEffect(() => {
        // TODO: get this data from backend
        setAppIds([
            {label: "id1", value: "1"},
            {label: "id2", value: "2"},
            {label: "id3", value: "3"},
            {label: "id4", value: "4"},
            {label: "id5", value: "5"}
        ])
        setCurrentAppId({label: "id1", value: "1"})
    }, []);



    const appIdChangeHandler = (e: any) => {
        console.log("current selected app id")
        console.log(e.detail.selectedOption)
        setCurrentAppId(e.detail.selectedOption)
    };

    const submitHandler = () => {
    //     Check if redux makes sense here, or should we use different react states only
        const req ={
            "currentAppId" : currentAppId,
            "parentTestCases": selectedParentCheckboxes,
            "childTestCases": selectedChildCheckboxes,
            "selectAll": selectAllChecked,
            "testInstructions": placeHolderValue,
            "selectedFile": uploadFile
        }
        console.log("Button clicked")
        console.log(req);
    }

    return (
        <CustomContainer title="TEST YOUR APP" content="" description="" variant="h1">
            <>
                <div style={{marginBottom: '20px'}}>
                    <AppDropDown
                        options={appIds}
                        currentOption={currentAppId}
                        onChange={(e: any) => appIdChangeHandler(e)}/>
                </div>
                <UploadFileContainer uploadFile={uploadFile} setUploadFile={setUploadFile}/>
                <TestCasesContainer selectedParentCheckboxes={selectedParentCheckboxes}
                                    setSelectedParentCheckboxes={setSelectedParentCheckboxes}
                                    selectedChildCheckboxes={selectedChildCheckboxes}
                                    setSelectedChildCheckboxes={setSelectedChildCheckboxes}
                                    selectAllChecked={selectAllChecked}
                                    setSelectAllChecked={setSelectAllChecked}
                                    placeHolderValue={placeHolderValue}
                                    setPlaceHolderValue={setPlaceHolderValue}
                />
                <CustomButton
                    variant="primary"
                    value="Submit and Run Test"
                    style={{textAlign: 'center'}}
                    onClick={submitHandler}/>
            </>
        </CustomContainer>
    );
};

export default ParentComponent;
