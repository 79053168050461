import React, { useEffect, useRef, useState } from 'react';
import useAxios from 'src/utils/hooks/useAxios'
import { apiHandler } from 'src/utils/services/apiService';

export default () => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ddfToken, setDdfToken] = useState();
    const [ddfExpiry, setDdfExpiry] = useState();
    const { axios } = useAxios();

    useEffect(() => {
        fetchDdfToken();
    }, []);

    const fetchDdfToken = async () => {
        try {
            const response = (await apiHandler(axios).fetchDdfToken())?.data;
            console.log(response);
            console.log(response?.response, response?.expiry)
            setDdfExpiry(response?.expiry);
            console.log({ddfExpiry}, {ddfToken})
            setDdfToken(response?.response);
            console.log({ddfExpiry}, {ddfToken})
            return response;
        } catch (error) {
            console.log(error as string);
        }
    };
    const alphads2Url = 'https://ftdf-alpha-apac-ap-south-1-alb.devicefarm.ds2.amazon.dev/?jwt='
    const betads2Url = 'https://ftdf-beta-apac-ap-south-1-alb.devicefarm.ds2.amazon.dev/#!/devices'
    
    return (
        <iframe src={alphads2Url + ddfToken} width='100%' height={windowSize.current[1] - 100} frameBorder={0} allowFullScreen={true} scrolling='auto'></iframe>
    );
}
