import React, { useEffect, useState } from 'react';
import { Button, ColumnLayout, SpaceBetween } from "@amzn/awsui-components-react";
import "src/index.scss"
import Box from '@amzn/awsui-components-react/polaris/box';
import OverviewContainer from 'src/components/Container/OverviewContainer';
import useAxios from 'src/utils/hooks/useAxios'
import Automationlogo from "src/Assets/images/autoTest1.png"
import Manuallogo from "src/Assets/images/manualtest1.png"
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate()

export default (props: { automatedtesting: any; manualTesting: any; }) => {
  const [ddfToken, setDdfToken] = useState();
  const { axios } = useAxios();

  useEffect(() => {
    fetchDdfToken();
  }, [props]);

  const fetchDdfToken = async () => {
    try {
      const response = await axios.get(`/apps-and-games/console/app-quality-central-api/ddf-jwt-token`);
      if (response) {
        console.log(response);
        console.log(response?.data);
      }
    } catch (error) {
      console.log(error as string);
    }
  };

  const manualcontainerProps = {
    buttonValue: "Access Test Lab",
    logoType: Manuallogo,
    route: "/apps-and-games/console/app-quality-central/?SelectedTab=LiveDeviceInteraction"
  }

  const AutomatedcontainerProps = {
    buttonValue: "Run Automated Testing",
    logoType: Automationlogo,
    route: "/apps-and-games/console/app-quality-central/?SelectedTab=test"
  }

  return (
    <>
    <div className='OverviewBg'>
    <SpaceBetween direction="horizontal" size="xs">
      <ColumnLayout columns={12}>
      <div className='OverviewText'>
          This is a paragraph. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Ut luctus tempor
          dolor ac accumsan. This is a paragraph. Lorem
          ipsum dolor sit amet, consectetur adipiscing
          elit. Ut luctus tempor dolor ac accumsan.s
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Ut luctus tempor
          dolor ac accumsan. This is a paragraph. Lorem
          ipsum dolor sit amet, consectetur adipiscing
          elit. Ut luctus tempor dolor ac accumsan.
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Ut luctus tempor
          dolor ac accumsan. This is a paragraph. Lorem
          ipsum dolor sit amet, consectetur adipiscing
          elit. Ut luctus tempor dolor ac accumsan.
        </div>
        <div style={{ display: 'flex', justifyContent:'center', gap: '2%'}}>
        <br/>
          <div>
            {props.automatedtesting && <OverviewContainer {...AutomatedcontainerProps} />}
          </div>
          <div >
            {props.manualTesting && <OverviewContainer {...manualcontainerProps} />}
          </div>
          <br/>
        </div>
      </ColumnLayout>
      <ColumnLayout columns={12}>
        
      </ColumnLayout>
      </SpaceBetween>
      </div>
    </>
  );
}