import React, { useState, ChangeEvent } from "react";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";

interface CustomTextareaProps {
    val: string;
    onChange: any;
    placeholder?: string;
}


export  default (props:CustomTextareaProps)=>{
    const {val, onChange, placeholder} = props;
    return (
        <Textarea
            value={val}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
}
