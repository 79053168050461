import React, {useEffect, useState} from "react";
import Select, {SelectProps} from '@amzn/awsui-components-react/polaris/select';

export  default (props: { options: any; currentOption: any; onChange: any; title?: "" | undefined; className?: "" | undefined; })=>{
    const { options, currentOption, onChange, title = '', className = '' } = props;

    return (
        <Select
            selectedOption={currentOption}
            onChange={onChange}
            options={options}
        />
    );
}
