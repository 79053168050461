import React, { useEffect, useState } from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import { Box, SpaceBetween, CollectionPreferences, CollectionPreferencesProps, Grid, Header, Pagination } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import Button from "@amzn/awsui-components-react/polaris/button";


export default (props: { buttonValue: any; logoType: any; route:any}) => {
  const navigate = useNavigate();
  return (
    <Container
      media={{
        content: (
          <div style={{}}>
          <img src={props.logoType} alt='Testing'/>
          </div>
        ),
        position: "side"
      }}
    >
      <SpaceBetween direction="vertical" size="xs">
        <SpaceBetween direction="vertical" size="xs">
        <div style={{display:'flex', justifyContent:'center'}}>
          {/* <Box variant="p"> */}
          This is a paragraph. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Ut luctus tempor
          dolor ac accumsan. This is a paragraph. Lore
          {/* </Box> */}
          </div>
        </SpaceBetween>
        <div style={{display:'flex', alignItems:'right', justifyContent:'right'}}>
        <Button
        variant={'primary'}
              iconName="caret-right-filled"
              iconAlign="right"
              onClick={() => {
                return navigate(props.route)
              }}
            >
              {props.buttonValue}
        </Button>
        </div>
      </SpaceBetween>
    </Container>
  );
}