import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from 'src/utils/constants/config';

interface UseAxiosProps {
    handleError?: boolean;
}

const axiosHook = axios.create({
    baseURL: config.BASE_URLS[config.ENV],
});


axiosHook.interceptors.response.use(
    (res) => {
        if (res?.data?.pageNumber === 0) {
            console.log("Succesfully Data Retrieved")
        }
        return res;
    },
    (err) => {

        // Generic error handling
        const errRes = err.response;
        console.log('API ERROR at', new Date());
        console.log('Error Message:', err.message);
        console.log('Error Response', err.response);
        if (errRes.status >= 400) {
            console.log((errRes.data && errRes.data.message) || `Something went wrong. ${err.message}`);
        }

        throw err;
    },
);

const useAxios = () => {
    return {
        axios: axiosHook
    };
};

export default useAxios;
