import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from 'src/containers/Router/Router';
import { Box, theme } from '@amzn/lola-ui';
import styled from '@emotion/styled';

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '1400px',
    margin: '0 auto',
  
    // specify properties here to prevent them being overridden by DevPortal
    color: theme.palette.text.primary,
    lineHeight: '1.0',
    WebkitFontSmoothing: 'auto',
    MozOsxFontSmoothing: 'auto',
  }));

export default () => {
    return(
        <Container>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </Container>
        
    );
}