import React from "react";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";

interface CustomCheckboxProps {
    label: string;
    checkedState: boolean;
    onChange: any;
    disabled?: boolean
}
export  default (props:CustomCheckboxProps)=>{
    const {label,checkedState,onChange,disabled} = props;

    return (
        <Checkbox
            onChange={onChange}
            checked={checkedState}
            disabled={disabled}
        >
            {label}
        </Checkbox>
    );
}

