import React from 'react';
import ReactDOM from 'react-dom';
import { LolaThemeProvider } from '@amzn/lola-ui';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import theme from '@react/web-components/__theme__/Lola.json';
import App from './containers/App/App';
import './logger';
import './index.scss';
import '@amzn/awsui-global-styles/polaris.css';

 

ReactDOM.render(
  <LolaThemeProvider>
      <StyledComponentsThemeProvider theme={theme}>
        <App />
      </StyledComponentsThemeProvider>
    </LolaThemeProvider>,
  document.getElementById('app-quality-central-root')
);
